import React, { useState, useEffect, useContext } from "react";
import styles from "./styles.module.scss";
import { context } from "../../../Store/Context";
import TournamentCard from "../../Global/tournamentCard/TournamentCard";
import ReactPaginate from 'react-paginate';
import ReactDOM from 'react-dom';
import tournaments from "../../../api/tournaments";


function Tournaments() {
  const { state } = useContext(context);
  const [name, setName] = useState('');
  const [foundUsers, setFoundUsers] = useState([]);

  const userTournaments = state.tournaments.filter(
    (tournament) => tournament.createdBy === state.user.userName
  ).sort((a, b) => a.status > b.status ? 1 : -1);

  const userTournamentsSerch = state.tournaments.filter(
    (tournament) => tournament.createdBy === state.user.userName
  );
  const allTournaments = state.tournaments.sort((a, b) => a.status > b.status ? 1 : -1);

  const filter = (e) => {

    const keyword = e.target.value;


    if (keyword !== '') {

      if(state.user.userName === '') {
        setFoundUsers(state.tournaments.filter(tournament => tournament.title.toLowerCase().includes(keyword.toLowerCase())));
      }
      else {
        setFoundUsers(userTournamentsSerch.filter(tournament => tournament.title.toLowerCase().includes(keyword.toLowerCase())));
      }
    } else {
      setFoundUsers([]);
      // If the text field is empty, show all users
    }

    setName(keyword);

  };

  function Items({ currentItems }) {
    
    return (
      <div className={styles.grid}>
        {currentItems &&
          currentItems.map((item, id) => (
            <div>
              {item.status === "new" && (
                <TournamentCard key={`tournament-${id}`} tournament={item} color="yellow"/>
              )}
              {item.status === "ongoing" && (
                <TournamentCard key={`tournament-${id}`} tournament={item} color="green"/>
              )}
              {item.status === "test_status" && (
                <TournamentCard key={`tournament-${id}`} tournament={item} color="grey"/>
              )}
            </div>
          ))}
          {(currentItems && currentItems.length === 0) && (
           
              <h1>Tournament Not Found</h1>
          
          )}
      </div>
    );
  }

  function PaginatedItems({ itemsPerPage, list }) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [total, setTotal] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
    const [endOffsetD, setEndOffsetD] = useState(0);
  
    useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(list.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(list.length / itemsPerPage));
      setEndOffsetD(endOffset);
      setTotal(list.length);
    }, [itemOffset, itemsPerPage]);
  
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % list.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };
  
    return (
      <>
        {name !== '' ? (
          <Items currentItems={foundUsers} />
        ) : (
          <Items currentItems={currentItems} />
        )}
        <div className="pagination-container">
          <div>
            <h3>Showing {itemOffset}-{endOffsetD} out of {total}</h3>
          </div>
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< Prev"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLabelBuilder={(pageIndex) => `${pageIndex}`}
            previousLinkClassName="previousLinkA pageNum"
            nextLinkClassName="nextLinkA pageNum"
            pageClassName="pageNum"
          />
        </div>
      </>
    );
  }

  return (
    <section className={styles.tournaments}>
      <div className="container-md">
        <div className="tournament-title">
          <h1>Your Tournaments</h1>
          <input
            type="search"
            value={name}
            onChange={filter}
            className="input"
            placeholder="Search for tournament"
          />
        </div>
        {state.user.userName != "" ? (
          <PaginatedItems itemsPerPage={3} list={userTournaments} />
        ):(
          <PaginatedItems itemsPerPage={3} list={allTournaments} />
        )}
      </div>
    </section>
  );
}

export default Tournaments;
