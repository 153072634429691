import React from "react";
import Hero from "../Components/Home/Hero/Hero";
import Tournaments from "../Components/Home/Tournaments/Tournaments";
import Edit from "../Components/Edit/Edit";

function EditPage({tournament}) {
  return (
    <main>
      <Edit tournament={tournament}/>
    </main>
  );
}

export default Edit;
