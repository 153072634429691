import React from "react";
import styles from "./styles.module.scss";

function StartTour({ tournament }) {
  console.log(tournament)
  return (
    <section className={styles.tournamentHero}>
      <div className="container-md">
        {/* <h3>{tournament?.title}</h3> */}
      </div>
    </section>
  );
}

export default StartTour;
