import React from "react";
import styles from "./styles.module.scss";
import { useNavigate, Navigate } from "react-router-dom";
import { useAlert } from 'react-alert'
import { Route } from "react-router-dom";
import Edit from "../../Edit/Edit";

function Hero({ tournament }) {

  let navigate = useNavigate();
  const alert = useAlert();

  const redirect = () => {
    navigate(`/tournament/${tournament.ugtid}/edit`);
  }

  return (
    <section className={styles.tournamentHero}>
      <div className="container-md">
        <div className={styles.topGlassTitle}>
          <h3>Tournament ID: {tournament?.ugtid}</h3>
          {/* <a onClick={redirect}>Edit</a> */}
        </div>

        <div className={styles.glassHero}>
        <h3>{tournament?.title}</h3>
        <ul>
          <li>
            <h4>Device</h4>
            <p>{tournament?.device}</p>
          </li>
          <li>
            <h4>Format</h4>
            <p>Single Elimination</p>
          </li>
          <li>
            <h4>Playtype</h4>
            <p>{tournament?.playtype}</p>
          </li>
          <li>
            <h4>Register by</h4>
            <p>{tournament?.registrationDate}</p>
          </li>
        </ul>
        <div className={styles.organisedBy}>
          <img
            src="https://espl.gg/assets/profile/profile_default.jpeg"
            alt=""
          />
          <div>
            <h4>Organised by</h4>
            <p>{tournament?.createdBy}</p>
          </div>
        </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
