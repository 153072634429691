import React, { useEffect, useState, useContext } from "react";
import { context } from "../../../Store/Context";
import Modal from "../../Global/Modal";
import FeatherIcon from 'feather-icons-react';
// import List from "../ParticipantsList/data";
import { useAlert } from 'react-alert'
import api from "../../../api/tournaments";

import {
  SingleEliminationBracket,
  Match,
  SVGViewer,
  createTheme,
} from "@g-loot/react-tournament-brackets";

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

const WhiteTheme = createTheme({
  textColor: { main: "#000000", highlighted: "#ffffffde", dark: "#ffffffde" },
  matchBackground: { wonColor: "#ffffff26", lostColor: "#ffffff26" },
  score: {
    background: { wonColor: "#212121", lostColor: "#212121" },
    text: { highlightedWonColor: "#7BF59D", highlightedLostColor: "#FB7E94" },
  },
  border: {
    color: "#CED1F2",
    highlightedColor: "#B388FF",
  },
  roundHeader: { backgroundColor: "#424242", fontColor: "#ffffffde" },
  connectorColor: "#B388FFE5",
  connectorColorHighlight: "#B388FF",
  svgBackground: "#212121",
});

export const WhiteThemeBracket = ({ tournament }) => {
  const size = useWindowSize();
  const [matchData, setMatchData] = useState([]);
  const [firstScore, setFirstScore] = useState("");
  const [lastScore, setLastScore] = useState("");
  const [participantID, setParticipantID] = useState("");
  const [matchID, setMatchID] = useState("");
  const [winner, setWinner] = useState("");
  const { state, dispatch } = useContext(context);
  const alert = useAlert();
  const [isValid, setIsValid] = useState(false);

  function closeModal() {
    dispatch({
      type: "GET_SET_SCORE_MODAL_STATE",
      payload: false,
    });
  }

  //8 participants = 1 round (8/2 = 4 matches)
  //4 participants = Semi-Final (4/2 = 2 matches)
  //2 participants = Final (2/2 = 1 match)

  //16 participants = 1 round (16/2 = 8 matches)
  //8 participants =  Quarter-Final (8/2 = 4 matches)
  //4 participants = Semi-Final (4/2 = 2 matches)
  //2 participants = Final (2/2 = 1 match)

  /*

  participants = ["1", "2", "3", "4", "5", "6", "7", "8"]

  for (let i = 0; i < participants.length; i++) {
    if (i % 2 === 0) {
      console.log(participants[i] + " vs " + participants[i + 1]);
      {
        id: participants.length + 1, // (8)
        nextMatchId: 4, 8/2
        participants: [],
        startTime: "2021-05-30",
        state: "SCHEDULED",
        tournamentRoundText: "3",
      }
    }
*/

  const openScoreModal = (num, status) => {
    if (num >= 2 && status === "SCHEDULED") {
      dispatch({
        type: "GET_SET_SCORE_MODAL_STATE",
        payload: true,
      });
    }
    else if (status === "DONE") {
      alert.error("This match is over!");
    }
    else {
      alert.error("You can't set a score for this match!");
    }
  };

  const saveMatches = async (matches) => {
    try {
      await api.put(`/latest/tournament/${tournament.ugtid}/${tournament.status}/matches`, matches)
      alert.success("Scores saved!");
    } catch (err) {
      alert.error(err.message);
    }
  }

  const validateForm = (e) => {
    e.preventDefault();
    firstScore === "" || lastScore === "" ? alert.error("Please enter a score!") 
    : isNaN(firstScore) || isNaN(lastScore) ? alert.error("Please enter a number!")
    : firstScore < 0 || lastScore < 0 ? alert.error("Please enter a positive number!")
    : updateScore()
  }

  const updateScore = () => {
    tournament?.matches.forEach(function (match, index) {
      if (match.id === matchID.id) {
        const newMatch = {
          id: matchID.id,
          nextMatchId: matchID.nextMatchId,
          participants: [
            {
              id: matchID.participants[0].id,
              resultText: firstScore,
              isWinner: firstScore > lastScore ? true : false,
              status: "PLAYED",
              name: matchID.participants[0].name,
            },
            {
              id: matchID.participants[1].id,
              resultText: lastScore,
              isWinner: firstScore < lastScore ? true : false,
              status: "PLAYED",
              name: matchID.participants[1].name,
            },
          ],
          startTime: matchID.startTime,
          state: "DONE",
          tournamentRoundText: matchID.tournamentRoundText,
        };

        matchData[index] = newMatch;

      }
      if (match.id === matchID.nextMatchId) {
        if (winner === "1") {
          matchData[index].participants.push(matchID.participants[0]);
        }
        else {
          matchData[index].participants.push(matchID.participants[1]);
        }
      }
    }
    );
    saveMatches(matchData);
    setFirstScore("");
    setLastScore("");
    console.log(matchData[2]);
    dispatch({
      type: "GET_SET_SCORE_MODAL_STATE",
      payload: false,
    });
  }

  useEffect(() => {
    const _matches = [...tournament?.matches];
    setMatchData(_matches);

  }, [tournament?.matches])


  // try {
  //   var listP = tournament?.participants.length;
  //   let seed = 0;
  //   for (let i = 0; i < matchData.length; i++) {
  //     if (matchData[i].tournamentRoundText === 1) {
  //       for (let j = seed; j < listP; j++) {
  //         matchData[i].participants.push({
  //           id: tournament?.participants[j].id,
  //           isWinner: false,
  //           name: tournament?.participants[j].name,
  //           picture: "teamlogos/client_team_default_logo",
  //           resultText: "",
  //           status: null,
  //         });
  //         matchData[i].participants.push({
  //           id: tournament?.participants[j + 1].id,
  //           isWinner: false,
  //           name: tournament?.participants[j + 1].name,
  //           picture: "teamlogos/client_team_default_logo",
  //           resultText: "",
  //           status: null,
  //         });
  //         seed = j + 2;
  //         break;
  //       }
  //     }
  //   }
  // }
  // catch (err) {
  //   console.log(err);
  // }

  return (
    <>
    
      
      {matchData?.length > 0 && (
        <div className="container-md" style={
          { marginBottom: "3.6rem" }
        }>
        <h1 className="title-bracket">Bracket</h1>
        <SingleEliminationBracket
          matches={matchData}
          matchComponent={Match}
          onMatchClick={(match) => {
            console.log(match);
            openScoreModal(match.match.participants.length, match.match.state);
            setMatchID(match.match);
          }}
          onPartyClick={(matchId, participantId) => {
            // openScoreModal();
            // setMatchID(matchId);
            // setParticipantID(participantId);
            // console.log(matchID);
            // console.log(participantID);
          }}
          theme={WhiteTheme}
          options={{
            style: {
              roundHeader: {
                backgroundColor: WhiteTheme.roundHeader.backgroundColor,
                fontColor: WhiteTheme.roundHeader.fontColor,
              },
              connectorColor: WhiteTheme.connectorColor,
              connectorColorHighlight: WhiteTheme.connectorColorHighlight,
            },
          }}
          svgWrapper={({ children, ...props }) => (
            <SVGViewer
              background={WhiteTheme.svgBackground}
              SVGBackground={WhiteTheme.svgBackground}
              width={size.width - 150}
              height={size.height - 150}
              {...props}
            >
              {children}
            </SVGViewer>
          )}
        />
             {state.scoreModalState && (

        <div className="tournament-modal-body-score">
          <div className="content">
            <div className="x" onClick={closeModal}><FeatherIcon icon="x-circle" stroke="white" strokeWidth="1" /></div>
            <h3>Match Scores</h3>
            <form onSubmit={validateForm}>
              <div className="score-form">
                <div className="items">
                  <h4>Participant</h4>
                  <h4>Score</h4>
                </div>
                <div className="items">
                  <h4>{matchID.participants[0].name}</h4>
                  <input
                    value={firstScore}
                    onChange={(e) => setFirstScore(e.target.value)}
                    type="text"
                    placeholder="0"
                    required
                  />
                </div>
                <div className="items">
                  <h4>{matchID.participants[1].name}</h4>
                  <input
                    value={lastScore}
                    onChange={(e) => setLastScore(e.target.value)}
                    type="text"
                    placeholder="0"
                    required
                  />
                </div>
                {/* <div className="items">
                    <h4>Winner</h4>
                    <select
                      value={winner}
                      onChange={(e) => setWinner(e.target.value)}
                      name=""
                      id=""
                    >
                      <option value="1">1v1</option>
                      <option value="2">2v2</option>
                    </select>
                  </div> */}
              </div>
              <button className="btn btn-primary">Save</button>
            </form>
          </div>
        </div>

      )}
            </div>
      )}
 

    </>
  );
};

// export const SingleElimination = () => (
//   <SingleEliminationBracket
//     matchComponent={Match}
//     matches={[
//       {
//         id: 19753,
//         nextMatchId: null,
//         participants: [],
//         startTime: "2021-05-30",
//         state: "SCHEDULED",
//         tournamentRoundText: "3",
//       },
//       {
//         id: 19754,
//         nextMatchId: 19753,
//         participants: [
//           {
//             id: "14754a1a-932c-4992-8dec-f7f94a339960",
//             isWinner: false,
//             name: "CoKe BoYz",
//             picture: "teamlogos/client_team_default_logo",
//             resultText: "",
//             status: null,
//           },
//         ],
//         startTime: "2021-05-30",
//         state: "SCHEDULED",
//         tournamentRoundText: "2",
//       },
//       {
//         id: 19755,
//         nextMatchId: 19754,
//         participants: [
//           {
//             id: "14754a1a-932c-4992-8dec-f7f94a339960",
//             isWinner: true,
//             name: "CoKe BoYz",
//             picture: "teamlogos/client_team_default_logo",
//             resultText: "Won",
//             status: "PLAYED",
//           },
//           {
//             id: "d16315d4-7f2d-427b-ae75-63a1ae82c0a8",
//             isWinner: false,
//             name: "Aids Team",
//             picture: "teamlogos/client_team_default_logo",
//             resultText: "Lost",
//             status: "PLAYED",
//           },
//         ],
//         startTime: "2021-05-30",
//         state: "SCORE_DONE",
//         tournamentRoundText: "1",
//       },
//       {
//         id: 19756,
//         nextMatchId: 19754,
//         participants: [
//           {
//             id: "d8b9f00a-0ffa-4527-8316-da701894768e",
//             isWinner: false,
//             name: "Art of kill",
//             picture: "teamlogos/client_team_default_logo",
//             resultText: "",
//             status: null,
//           },
//         ],
//         startTime: "2021-05-30",
//         state: "RUNNING",
//         tournamentRoundText: "1",
//       },
//       {
//         id: 19757,
//         nextMatchId: 19753,
//         participants: [],
//         startTime: "2021-05-30",
//         state: "SCHEDULED",
//         tournamentRoundText: "2",
//       },
//       {
//         id: 19758,
//         nextMatchId: 19757,
//         participants: [
//           {
//             id: "9397971f-4b2f-44eb-a094-722eb286c59b",
//             isWinner: false,
//             name: "Crazy Pepes",
//             picture: "teamlogos/client_team_default_logo",
//             resultText: "",
//             status: null,
//           },
//         ],
//         startTime: "2021-05-30",
//         state: "SCHEDULED",
//         tournamentRoundText: "1",
//       },
//       {
//         id: 19759,
//         nextMatchId: 19757,
//         participants: [
//           {
//             id: "42fecd89-dc83-4821-80d3-718acb50a30c",
//             isWinner: false,
//             name: "BLUEJAYS",
//             picture: "teamlogos/client_team_default_logo",
//             resultText: "",
//             status: null,
//           },
//           {
//             id: "df01fe2c-18db-4190-9f9e-aa63364128fe",
//             isWinner: false,
//             name: "Bosphorus",
//             picture: "teamlogos/r7zn4gr8eajivapvjyzd",
//             resultText: "",
//             status: null,
//           },
//         ],
//         startTime: "2021-05-30",
//         state: "SCHEDULED",
//         tournamentRoundText: "1",
//       },
//     ]}
//     svgWrapper={({ children, ...props }) => (
//       <SVGViewer width={1000} height={500} {...props}>
//         {children}
//       </SVGViewer>
//     )}
//   />
// );
