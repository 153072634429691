import React from "react";
import styles from "./styles.module.scss";


function AddParticipant({ tournament, changeTab }) {

  const eventHandler = () => {
    changeTab("profile");
  }

  return (
    <div>
    {tournament?.participants.length === 0 && (
    <section className={styles.tournamentHero}>
      <div className={styles.containerMd}>
        <div className="container-md">
          <div className={styles.startContainer}>
              <h1>Hello, {tournament?.createdBy}</h1>
              <p>To get started, simply add participants into the tournament.</p>
              <button onClick={eventHandler}>Add Participants</button>
          </div>
        </div>
      </div>
    </section>
    )}
    </div>
  );
}

export default AddParticipant;
