import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";
import { context } from "../../../Store/Context";
import { ListItem } from "./styles";
import { DragHandle, DeleteHandle } from "./DragHandle";
import api from "../../../api/tournaments";
import List from "./data";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useAlert } from 'react-alert'

function ParticipantsList({ tournament }) {
  const list = List.getList();
  const { id } = useParams();
  const alert = useAlert()
  const { state, dispatch } = useContext(context);
  const [pName, setPName] = useState("");
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    setParticipants(tournament.participants);
  }
    , [tournament.participants]);

  //handle remove item
  const handleRemove = async (idp, name) => {
    const newA = participants.filter((participant) => participant.id !== idp);
    await saveParticipants(newA);
    setParticipants(newA);
    alert.success('Participant ' + name + ' removed!');
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newParticipant = {
      name: pName,
    };
    try {
      await api.post(`/latest/tournament/${id}/${tournament.status}/participant`, newParticipant).then((res) => {
        setParticipants(res.data.Attributes.participants);
      });
      setPName("");
      alert.success('Participant ' + pName + ' added!');
    } catch (err) {
      alert.error(err)
    }
  };


  const saveParticipants = async (participants) => {
    try {
      await api.put(`/latest/tournament/${id}/${tournament.status}/update_participants`, participants);
    } catch (err) {
      console.log(err);
    }
  }

  const generateBracket = async (e) => {
    try {
      const generate = await api.post(`/latest/tournament/${tournament.ugtid}/${tournament.status}/matches`);
      const assign = await api.put(`/latest/tournament/${tournament.ugtid}/${tournament.status}/init`);
      
      let _updatedTournaments = state.tournaments;

      _updatedTournaments.matches = assign.data;

      console.log(_updatedTournaments);

      dispatch({
        type: "GET_TOURNAMENTS",
        payload: _updatedTournaments,
      });

      alert.success('Bracket generated!');

    }
    catch (err) {
      alert.error(err);
    }
  }

  return (

    <div className="container-md">
      <h1 className={styles.participantTitle}>Participants</h1>
      <p className={styles.participantSub}>To get started, simply add participants into the tournament.</p>
      <div className={styles.containerMd}>
        <DragDropContext
          onDragEnd={(param) => {
            const srcI = param.source.index;
            const desI = param.destination?.index;
            if (desI) {
              participants.splice(desI, 0, participants.splice(srcI, 1)[0]);
              console.log(participants)
              saveParticipants(participants);
            }
          }}
        >
          <Droppable droppableId="droppable-1">
            {(provided, _) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {participants.map((item, i) => (
                  <Draggable
                    key={item.id}
                    draggableId={"draggable-" + item.id}
                    index={i}
                  >
                    {(provided, snapshot) => (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{
                          ...provided.draggableProps.style,
                          boxShadow: snapshot.isDragging
                            ? "0 0 .4rem #666"
                            : "none",
                        }}
                      >
                        <DragHandle {...provided.dragHandleProps} />
                        <div className={styles.itemStyle}>
                          <span>{item.name}</span>
                          <DeleteHandle onClick={() => handleRemove(item.id, item.name)} />
                        </div>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

        </DragDropContext>
      </div>
      <div className={styles.inputDiv}>

     
      <form onSubmit={handleSubmit} className={styles.form}>
        <input
          className={styles.formInput}
          value={pName}
          onChange={(e) => setPName(e.target.value)}
          type="text"
          placeholder="Participant name"
          required
        />
        <button className={styles.formButton}>
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22.4697C17.5228 22.4697 22 17.9926 22 12.4697C22 6.94688 17.5228 2.46973 12 2.46973C6.47715 2.46973 2 6.94688 2 12.4697C2 17.9926 6.47715 22.4697 12 22.4697Z" stroke="black" strokeOpacity="0.6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 12.4697L12 8.46973L8 12.4697" stroke="black" strokeOpacity="0.6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 16.4697V8.46973" stroke="black" strokeOpacity="0.6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      </form>
      <button className={styles.gButton} onClick={generateBracket}>Generate Bracket</button>
      </div>
    </div>
  );
}

export default ParticipantsList;
