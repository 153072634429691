import { useEffect, useState, useReducer } from "react";
import { useRoutes } from "react-router-dom";
import "./App.scss";
import { reducer } from "./Store/Reducer";
import { initialState, context } from "./Store/Context";
import api from "./api/tournaments";
import Layout from "./Components/Global/Layout";
import Home from "./Pages/Home";
import Amplify, { Auth } from "aws-amplify";
import TournamentDetails from "./Pages/TournamentDetails";
import Organize from "./Pages/Organize";
import Edit from "./Pages/Edit";


Amplify.configure({
    Auth: {
        region: "ap-southeast-1",
        userPoolId: "ap-southeast-1_eZfqqJahD", //ap-southeast-1_DlgtMn9jV
        userPoolWebClientId: "5ntl0m5ilk4nh6g5jceilckvmr",
        oauth: {
            domain: "espl-prod-users.auth.ap-southeast-1.amazoncognito.com",
            redirectSignIn: "http://localhost:3000/",
            redirectSignOut: "http://localhost:3000/",
            responseType: "code",
        },
    },
});

function App() {
    const [, setTournaments] = useState([]);
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        Auth?.currentUserInfo()
            .then((data) => {
                dispatch({
                    type: "USER_INFO",
                    payload: {
                        email: data.attributes.email,
                        userName: data.username,
                        verified: data.attributes.email_verified,
                    },
                });
            })
            .catch((error) => {
                console.log(error);
            });

        const tournaments = async () => {
            try {
                const response = await api.get("/latest/tournaments");
                console.log(response.data);
                setTournaments(response.data);
                dispatch({
                    type: "GET_TOURNAMENTS",
                    payload: response.data,
                });
            } catch (err) {
                console.log(err);
            }
        };
        tournaments();
    }, []);

    let element = useRoutes([
        // {
        //     path: "/",
        //     element: <ProtectedRoute component={Home} />,
        // },
        { path: "/", element: <Home /> },
        { path: "tournament/:id", element: <TournamentDetails /> },
        { path: "join", element: <Organize /> },
        { path: "tournament/:id/edit", element: <Edit /> },
    ]);

    return (
        <context.Provider value={{ state, dispatch }}> 
            <Layout>{element}</Layout>
        </context.Provider>
    );
}

export default App;
