import React, { useState, useContext, useEffect } from "react";
import { getCoreUser, signInCognito, signInCore, signUpCore, isJoined, getMatches, getTournamentParticipants, getRounds, getMatch } from "../Auth";
import { context } from "../../../Store/Context";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { Auth } from "aws-amplify";
import { useAlert } from 'react-alert'

function LoginForm() {
  const alert = useAlert();
  const [error, setError] = useState("");
  const { state, dispatch } = useContext(context);
  function closeModal() {
    dispatch({
      type: "GET_MODAL_STATE",
      payload: false,
    });
    console.log(state);
  }

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
      signInCognito(username, password).then(cognitoData => { //sign in cognito
        signInCore(cognitoData.attributes.email).then(coreData => { //sign in core
          localStorage.setItem("coreToken", coreData.data.id_token); //save core token
          getTournamentParticipants().then(tournamentParticipants => {
            //console.log(tournamentParticipants);
          });
          getMatches().then(matches => {
            //console.log(matches);
          });
          getRounds().then(rounds => {
            rounds.data.forEach(element => {
              //console.log(element);
              element.matches.forEach(match => {
                //console.log(match);
                getMatch(match.id).then(match => {
                  //console.log(match);
                });
              });
            });
          });
          isJoined();
          closeModal();
        }).catch(err => { //if user does not exist in core 
          alert.error(err.message);
          signUpCore( //sign up core
              cognitoData.username, 
              cognitoData.attributes.email, 
              password, 
              cognitoData.attributes.phone_number, 
              cognitoData.attributes.given_name, 
              cognitoData.attributes.family_name, 
              cognitoData.attributes['custom:nric']
            ).then(coreSignUpData => {
            signInCore(cognitoData.attributes.email).then(coreData => {
              localStorage.setItem("coreToken", coreData.data.id_token);
              
              isJoined();
              closeModal();
            }).catch(err => {
              alert.error(err.message);
            });
          }).catch(err => {
            alert.error(err.message);
          });
        });
        dispatch({
          type: "USER_INFO",
          payload: {
            email: cognitoData.attributes.email,
            userName: cognitoData.username,
            verified: cognitoData.attributes.email_verified,
            logged: true,
          },
        });
        closeModal();
      }).catch(err => {
        alert.error(err.message);
        switch (err.code) {
          case "UserNotFoundException":
            signInCore(username).then(core => {
              localStorage.setItem("coreToken", core.data.id_token);
              getCoreUser().then(coreData => {
                var new_username  = coreData.data.login;
                if(new_username === "Anonymous") {
                  new_username = coreData.data.email.split("@")[0];
                }
                Auth.signUp({
                  username: new_username,
                  password: password,
                  email_verified: "true",
                  attributes: {
                    email: coreData.data.email,
                    phone_number: coreData.data.phone,
                    given_name: coreData.data.firstName,
                    family_name: coreData.data.lastName,
                    'custom:nric': coreData.data.nin,
                  },
                }).then(data => {
                  closeModal();    
                  dispatch({
                    type: "USER_INFO",
                    payload: {
                      userName: "",
                      migrationMessage: true,
                    },
                  });
                  dispatch({
                    type: "GET_CONFIRMATION_MODAL_STATE",
                    payload: true,
                  });      
                }).catch(err => {
                 
                  alert.error("Please verify your email address!");
                });
              });
            }).catch(err => {
              console.log(err);
            });
            break;
          default:
            console.log(err);
            break;
        }
      });
  };
  
  //google sign in
  const googleSignIn = () => {
    Auth.federatedSignIn({ provider: "Google" }).then((data) => {
      console.log(data);
    });
  };

  return (
    <div className="login-form">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <input
          placeholder="Username/Email Address"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />

        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        {error !== "" && <p className="errMessage">{error}</p>}

        <button className="btn-primary">Sign in</button>
      </form>
      <div className="login-footer">
        <button className="btn-secondary" onClick={googleSignIn}>Google</button>
        <p>
          Don't have an account? <a href="/join">Register</a>
        </p>
      </div>
    </div>
  );
}

export default LoginForm;
