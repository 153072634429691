import React from "react";
import Hero from "../Components/Home/Hero/Hero";
import Tournaments from "../Components/Home/Tournaments/Tournaments";

function Home() {
  return (
    <main>
      <Hero />
      <Tournaments />
    </main>
  );
}

export default Home;
