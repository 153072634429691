import React from "react";
import Hero from "../Components/Home/Hero/Hero";
import Tournaments from "../Components/Home/Tournaments/Tournaments";
import Organize from "../Components/Organize/Organize";

function Home() {
  return (
    <main>
      <Organize />
    </main>
  );
}

export default Home;
