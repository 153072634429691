import React, { useContext, useEffect, useState } from "react";
import Modal from "../../Global/Modal";
import { context } from "../../../Store/Context";
import styles from "./styles.module.scss";
import api from "../../../api/tournaments";
import { getUserId, isAuthenticated } from "../../Global/Auth";
import { useNavigate, Navigate } from "react-router-dom";
import { useAlert } from 'react-alert'

function Hero() {
  const { state, dispatch } = useContext(context);

  const [title, setTitle] = useState("");
  const [status, setStatus] = useState("");
  const [device, setDevice] = useState("");
  const [game, setGame] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [registrationDate, setRegistrationDate] = useState("");
  const [gameType, setGameType] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [country, setCountry] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  let navigate = useNavigate();
  const alert = useAlert()

  const join = () => {
    if(state.user.userName === "") {
      alert.error("Please login to create a tournament");
    } else {
      let path = `/join`;
      navigate(path);
    }
  };
  // const [updatedTournaments, setUpdatedTournamaents] = useState([]);
  // useEffect(() => {
  //   console.log(state.tournaments);
  //   setUpdatedTournamaents(state.tournaments);
  //   console.log(updatedTournaments);
  // }, []);
  const openTournamentModal = () => {
    dispatch({
      type: "GET_ADD_TOURNAMENT_MODAL_STATE",
      payload: true,
    });
  };

  useEffect(() => {
    isAuthenticated().then((user) => {
      setCreatedBy(user.username);
      console.log(user.username);
    });
  }
  , []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newTournament = {
      title: title,
      status: status,
      game: game,
      startDate: startDate,
      endDate: endDate,
      registrationDate: registrationDate,
      participants: [],
      matches: [],
      imgUrl: imgUrl,
      createdBy: createdBy,
      device: device,
      country: country,
      playtype: gameType,
    };
    try {
      const response = await api.post("/latest/tournament", newTournament);
      console.log(response);
      let _updatedTournaments = state.tournaments;
      console.log(_updatedTournaments);
      _updatedTournaments.push(newTournament);
      console.log(_updatedTournaments);
      dispatch({
        type: "GET_TOURNAMENTS",
        payload: _updatedTournaments,
      });
      console.log(state.tournaments);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <section className={styles.hero}>
      <div className="container-md">
        <div className={styles.heroContent}>
          <h1>Join us Today</h1>
          <p>Create and host your own tournaments with ease. </p>
          <button onClick={join}>
            <p>Organize</p>
            <div>
                <svg
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.89526 17.2145L15.6095 11.5002L9.89526 5.78589"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
          </button>
        </div>

        {state.addTournamentModalState && (
          <Modal>
            <div className="tournament-modal-body">
              <div className="content">
                <h3>Create tournament</h3>
                <form onSubmit={handleSubmit}>
                  <input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    type="text"
                    placeholder="Tournament name"
                    required
                  />
                  <input
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    type="text"
                    placeholder="status"
                    required
                  />
                  <select
                    value={device}
                    name=""
                    id=""
                    onChange={(e) => setDevice(e.target.value)}
                  >
                    <option value="Mobile">Mobile</option>
                    <option value="PC">PC</option>
                    <option value="Console">Console</option>
                  </select>
                  <input
                    type="date"
                    value={startDate}
                    placeholder="Start date"
                    onChange={(e) => setStartDate(e.target.value)}
                    min="2022-01-01"
                    max="2023-01-01"
                  />

                  <input
                    type="date"
                    value={endDate}
                    placeholder="End date"
                    onChange={(e) => setEndDate(e.target.value)}
                    min="2022-01-01"
                    max="2023-01-01"
                  />
                  <input
                    type="date"
                    value={registrationDate}
                    placeholder="Registration date"
                    onChange={(e) => setRegistrationDate(e.target.value)}
                    min="2022-01-01"
                    max="2023-01-01"
                  />
                  <input
                    type="text"
                    value={imgUrl}
                    onChange={(e) => setImgUrl(e.target.value)}
                    placeholder="img URL"
                    required
                  />

                  <input
                    value={createdBy}
                    onChange={(e) => setCreatedBy(e.target.value)}
                    type="text"
                    placeholder="createdBy"
                    required
                  />

                  <select
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    name=""
                    id=""
                  >
                    <option value="Malaysia">Malaysia</option>
                    <option value="Indonasia">Indonasia</option>
                    <option value="Japan">Japan</option>
                  </select>
                  <select
                    value={game}
                    onChange={(e) => setGame(e.target.value)}
                    name=""
                    id=""
                  >
                    <option value="Dota 2">Dota 2</option>
                    <option value="League of legends">League of legends</option>
                    <option value="Call of duty">Call of duty</option>
                  </select>
                  <select
                    value={gameType}
                    onChange={(e) => setGameType(e.target.value)}
                    name=""
                    id=""
                  >
                    <option value="1v1">1v1</option>
                    <option value="2v2">2v2</option>
                    <option value="3v3">3v3</option>
                  </select>
                  {/* <input
            type="time"
            id="appt"
            name="appt"
            min="09:00"
            max="18:00"
            required
          /> */}
                  <button className="btn-primary">Game on!</button>
                </form>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </section>
  );
}

export default Hero;
