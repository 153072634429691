import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { context } from "../Store/Context";
import Hero from "../Components/TournamentDetail/Hero/Hero";
import Placeholder from "../Components/TournamentDetail/Placeholder/Placeholder";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { WhiteThemeBracket } from "../Components/TournamentDetail/SingleBrackets/SingleBrackets";
import StartTour from "../Components/TournamentDetail/StartTour/StartTour";
import AddParticipant from "../Components/TournamentDetail/AddParticipant/AddParticipant";
import ParticipantsList from "../Components/TournamentDetail/ParticipantsList/ParticipantsList";
import { isAuthenticated } from "../Components/Global/Auth";

function TournamentDetails() {
  const { state } = useContext(context);
  const { id } = useParams();

  const [tournament, setTournament] = useState(null);
  const [user, setUser] = useState(null);
  const [key, setKey] = useState("");
  useEffect(() => {
    setKey("home")
    const _tournaments = [...state.tournaments];
    const idx = _tournaments.findIndex((t) => t.ugtid === id);
    if (idx > -1) setTournament(_tournaments[idx]);

    isAuthenticated().then((user) => {
      setUser(user.username);
      console.log(user.username);
    });
  }, [id, state.tournaments]);

  const handleSelect = (key) => {
    console.log(key);
    setKey(key);
  }

  return (
    // <h1>Hello world {tournament?.ugtid}</h1>
    <div>
      {state.user.userName === tournament?.createdBy ? (
        <main>
          <Placeholder tournament={tournament} />
          <Hero tournament={tournament} />
          <Tabs
            defaultActiveKey="home"
            onSelect={(key) => setKey(key)}
            activeKey={key}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="home" title="Brackets">
              <StartTour tournament={tournament} />

              {(tournament && tournament?.participants?.length > 0) ? (
                <WhiteThemeBracket tournament={tournament} />
              ) : ( 
                <AddParticipant tournament={tournament} changeTab={handleSelect}/>
              )}
            </Tab>
            <Tab eventKey="profile" title="Participants">
              <ParticipantsList tournament={tournament} />
            </Tab>
            {/* <Tab eventKey="contact" title="Edit" disabled>
              <h2>tabs3</h2>
            </Tab> */}
          </Tabs>
        </main>
      ) : (
        <main>
          <Placeholder tournament={tournament} />
          <Hero tournament={tournament} />
          {(tournament && tournament?.participants?.length > 0) && (
            <WhiteThemeBracket tournament={tournament} />
          )}
        </main>


      )}
    </div>
  );
}

export default TournamentDetails;
