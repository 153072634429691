import { Auth } from "aws-amplify";
import axios from "axios";

export const signUpCognito = (
  username,
  password,
  email,
  phone,
  firstname,
  lastname,
  nric
) => {
  return Auth.signUp({
    username: username,
    password: password,
    attributes: {
      email: email,
      phone_number: phone,
      given_name: firstname,
      family_name: lastname,
      "custom:nric": nric,
    },
  });
};

export const signInCognito = (username, password) => {
  return Auth.signIn(username, password);
};

export const confirmUser = (username, code) => {
  return Auth.confirmSignUp(username, code);
};

export const isAuthenticated = () => {
  return Auth.currentAuthenticatedUser();
};

export const getToken = () => {
  return Auth.currentSession();
};

//get user id
export const getUserId = () => {
  return Auth.currentAuthenticatedUser().then((user) => {
    return user.attributes.sub;
  });
}

//sign in google
export const signInGoogle = () => {
  return Auth.federatedSignIn({ provider: "Google" });
};

export const signInCore = (email) => {
  return axios.post(`https://backend.espl.gg/api/authenticate-social`, {
    email: email,
  });
};

export const updateUserAttributes = async (
  firstname,
  lastname,
  nric,
  birthdate
) => {
  const user = await Auth.currentAuthenticatedUser();
  if (user) {
    Auth.updateUserAttributes(user, {
      given_name: firstname,
      family_name: lastname,
      "custom:nric": nric,
      birthdate: birthdate,
    });
    return true;
  }
  return;
};

export const signUpCore = (
  username,
  email,
  password,
  phone,
  firstname,
  lastname,
  nric
) => {
  const user = {
    login: username,
    email: email,
    firstName: firstname,
    lastName: lastname,
    nin: nric,
    password: password,
    subscription: false,
    langKey: "en-US",
    source: "ESPL",
    phone: phone,
    countryId: 1786,
  };

  return axios.post(`https://backend.espl.gg/api/register`, user);
};

export const joinTournament = async () => {
  let _token = localStorage.getItem("coreToken");

  const user = await axios.get("https://backend.espl.gg/api/account", {
    headers: {
      Authorization: `Bearer ${_token}`,
    },
  });

  if (user) {
    const res = await axios.post(
      "https://backend.espl.gg/api/tournament/join",
      {
        participantId: user.data.id,
        tournamentId: 4891010,
      },
      {
        headers: {
          Authorization: `Bearer ${_token}`,
        },
      }
    );
    return res;
  }
  return "No user found!";
};

export const getCoreUserID = async () => {
  let _token = localStorage.getItem("coreToken");
  const user = await axios.get("https://backend.espl.gg/api/account", {
    headers: {
      Authorization: `Bearer ${_token}`,
    },
  });
  return user.data.id;
};

export const getCoreUser = async () => {
  let _token = localStorage.getItem("coreToken");
  const user = await axios.get("https://backend.espl.gg/api/account", {
    headers: {
      Authorization: `Bearer ${_token}`,
    },
  });
  return user;
};

export const getSocialUser = async () => {
  let _token = localStorage.getItem("coreToken");
  const user = await axios.get(
    "https://backend.espl.gg/api/social-network-types",
    {
      headers: {
        Authorization: `Bearer ${_token}`,
      },
    }
  );
  return user;
};

export const updateUserProfile = () => {
  let _token = localStorage.getItem("coreToken");
  return axios.post("https://backend.espl.gg/api/profile", {
    headers: {
      Authorization: `Bearer ${_token}`,
    },
  });
};

export const addConditionalJoin = async (conditions) => {
  let _token = localStorage.getItem("coreToken");
  const user = await Auth.currentUserInfo();
  if (user) {
    const profile = axios.post(
      "https://backend.espl.gg/api/profile",
      {
        login: user.username,
        email: user.attributes.email,
        firstName: user.attributes.given_name,
        lastName: user.attributes.family_name,
        nin: user.attributes["custom:nric"],
        dateOfBirth: user.attributes.birthdate,
        socialNetworks: [
          {
            typeId: 4666952,
            url: conditions[0],
          },
          {
            typeId: 4666953,
            url: conditions[1],
          },
          {
            typeId: 4666954,
            url: conditions[2],
          },
          {
            typeId: 4666955,
            url: conditions[3],
          },
          {
            typeId: 8704,
            url: conditions[4],
          },
        ],
      },
      {
        headers: {
          Authorization: `Bearer ${_token}`,
        },
      }
    );
    return profile;
  }
  return;
};

export const getConditionalJoins = async () => {
  let _token = localStorage.getItem("coreToken");
  const conditions = await axios.get(
    "https://backend.espl.gg/api/check-tournament-conditional-join/4891010",
    {
      headers: {
        Authorization: `Bearer ${_token}`,
      },
    }
  );
  return conditions;
};

export const getTournamentParticipants = async () => {
  let _token = localStorage.getItem("coreToken");
  const participants = await axios.get(
    "https://backend.espl.gg/api/participants/4891010",
    {
      headers: {
        Authorization: `Bearer ${_token}`,
      },
    }
  );
  return participants;
};

export const getMatches = async () => {
  let _token = localStorage.getItem("coreToken");
  const matches = await axios.get(
    "https://backend.espl.gg/api/matches",
    {
      headers: {
        Authorization: `Bearer ${_token}`,
      },
    }
  );
  return matches;
};

export const getRounds = async () => {
  let _token = localStorage.getItem("coreToken");
  const rounds = await axios.get(
    "https://backend.espl.gg/api/rounds/5011451",
    {
      headers: {
        Authorization: `Bearer ${_token}`,
      },
    }
  );  
  return rounds;
};

export const getMatch = async (id) => {
  let _token = localStorage.getItem("coreToken");
  const rounds = await axios.get(
    "https://backend.espl.gg/api/match/"+id,
    {
      headers: {
        Authorization: `Bearer ${_token}`,
      },
    }
  );  
  return rounds;
};

export const isJoined = () => {
  let joined = false;
  getCoreUserID()
    .then((id) => {
      axios
        .get("https://backend.espl.gg/api/all-tournament-participants/4891010")
        .then((res) => {
          //console.log(res);
          res.data.forEach((element) => {
            if (element.participant.id === id) {
              joined = true;
            }
          });
        })
        .then(() => {
          localStorage.setItem("joined", joined);
        });
    })
    .catch((err) => {
      console.log(err);
    });
};
