import { useState, forceUpdate, useContext } from "react";
import styles from "./styles.module.scss";
import api from "../../../api/tournaments";
import { useParams } from "react-router-dom";
import { context } from "../../../Store/Context";
import { useAlert } from "react-alert";

function StartTour({ tournament }) {
  
  const [start, setStart] = useState(false);
  const alert = useAlert();

  const switchStart = () => {
    start ? setStart(false) : setStart(true);
    alert.success(start ? 'Tournament ended!' : 'Tournament started!');
  }

  return (
    <div>
    {tournament.matches.length > 0 && (
    <section className={styles.tournamentHero}>
      <div className={styles.containerMd}>
        <div className="container-md">
          <div className={styles.startContainer}>
              <h1>Hello, {tournament?.createdBy}</h1>
              <p>Please check your brackets and participants before beginning the tournament!</p>
              <button onClick={switchStart}>{start ? "End Tournament" : "Start Tournament"}</button>
          </div>
        </div>
      </div>
    </section>
    )}
    </div>
  );
}

export default StartTour;
