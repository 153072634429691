import React, { useState, useContext } from "react";
import logo from "./assets/LOGO.svg";
import { context } from "../../../Store/Context";
import LoginModal from "../Modals/LoginModal";
import LoginForm from "../Forms/LoginForm";
import { Auth } from "aws-amplify";


function Header() {

  const [toggle, setToggle] = useState(false);

  const handleButtonClick = () => {
    setToggle(!toggle);
  };

  const { state, dispatch } = useContext(context);
  function handleModal() {
    dispatch({
      type: "GET_MODAL_STATE",
      payload: true,
    });
  }

  const signOut = () => {
    Auth.signOut();
    localStorage.clear();
    dispatch({
      type: "USER_INFO",
      payload: {
        email: "",
        userName: "",
        verified: false,
        logged: false,
      },
    });
  };

  const redirect = () => {
    window.location.href = "/";
  }

  return (
    <div className="top-header">
    <header>
      <div className="container-md">
        <div className="col1">
          <div className="logo">
            <img src={logo} alt="" onClick={redirect}/>
          </div>

          {/* <span>TOURNAMENT</span> */}
        </div>

        <div className="col2">


          <div className="user-name">
          {state.user.userName && (
            <div className="user-logged">
              <span>
                <img
                  src="https://espl.gg/assets/profile/profile_default.jpeg"
                  alt=""
                />
              </span>
              <h4>{state.user.userName}</h4>
            </div>
            )}
            <button type="button" className="button" onClick={handleButtonClick}>
              {toggle ? "✕" : "☰"}
            </button>

            <div className={toggle ? "dropdown" : "dropdown-close"}>
              <h3>Tournaments</h3>
              {state.user.userName === "" ? (
                <button className="login" onClick={handleModal}>Log In</button>
              ) : (
                <button className="login" onClick={signOut}>Log Out</button>
              )}
              <button className="core-login"><a href="https://espl.gg/" target="__blank">ESPL.GG</a></button>
            </div>

          </div>
        </div>
      </div>
      
    </header>

      {state.loginModalState && (
                <LoginModal>
                  <LoginForm />
                </LoginModal>
              )}
    </div>
  
    
  );
}

export default Header;
