import React, { useContext } from "react";
import { context } from "../../Store/Context";

function Modal({ children }) {
  const { dispatch } = useContext(context);
  function closeModal() {
    dispatch({
      type: "GET_ADD_TOURNAMENT_MODAL_STATE",
      payload: false,
    });
    dispatch({
      type: "GET_SET_SCORE_MODAL_STATE",
      payload: false,
    });
  }
  return (
    <div className="modal-layout">
      <div className="overlay" onClick={closeModal}></div>
      <div className="login-modal">
        <div className="lmodal-body">{children}</div>
        {/* <div className="lmodal-footer">
          <p>Don't have an account?</p>
         
          <p className="yo" onClick={closeModal}>
            Register
          </p>
        </div> */}
      </div>
    </div>
  );
}

export default Modal;
