import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import gameImg from "../assets/m.svg";

function TournamentCard({ tournament, color }) {
  return (
    <article>
      <Link to={`tournament/${tournament.ugtid}`}>
        <div className={styles.grid}>
          <div className={`${styles.content} ${color}`}>
            <img src={gameImg} alt="" />
            <div className={styles.flexCol}>
              <h3>{tournament.title}</h3>
              <div className={styles.statusGrid}>
                <span className={styles.status}>tournament {tournament?.status}</span>
                <div className={color}>
                  <span>
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.9333 14.47V13.1366C13.9333 12.4294 13.6523 11.7511 13.1522 11.251C12.6522 10.7509 11.9739 10.47 11.2666 10.47H5.9333C5.22605 10.47 4.54778 10.7509 4.04768 11.251C3.54758 11.7511 3.26663 12.4294 3.26663 13.1366V14.47"
                        stroke="#FFC107"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.59999 7.8033C10.0727 7.8033 11.2667 6.6094 11.2667 5.13664C11.2667 3.66388 10.0727 2.46997 8.59999 2.46997C7.12723 2.46997 5.93332 3.66388 5.93332 5.13664C5.93332 6.6094 7.12723 7.8033 8.59999 7.8033Z"
                        stroke="#FFC107"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span>{tournament?.participants.length}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.actions} ${color}`}>
            {/* <span>
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24 6.75C25.6178 6.74994 27.1726 7.37722 28.3375 8.49992C29.5023 9.62262 30.1865 11.1533 30.246 12.77L30.25 13.001L37 13C37.4515 13.0002 37.8855 13.1749 38.2112 13.4876C38.5369 13.8003 38.7291 14.2268 38.7477 14.6779C38.7663 15.1291 38.6098 15.5699 38.311 15.9084C38.0121 16.2468 37.594 16.4566 37.144 16.494L37 16.5H35.833L34.206 38.07C34.1253 39.1387 33.6438 40.1375 32.8579 40.8663C32.072 41.5951 31.0398 42 29.968 42H18.032C16.9602 42 15.928 41.5951 15.1421 40.8663C14.3562 40.1375 13.8747 39.1387 13.794 38.07L12.166 16.5H11C10.5607 16.5 10.1374 16.3347 9.81429 16.037C9.49119 15.7392 9.2919 15.3309 9.256 14.893L9.25 14.75C9.25004 14.3107 9.41533 13.8874 9.71305 13.5643C10.0108 13.2412 10.4191 13.0419 10.857 13.006L11 13H17.75C17.75 9.702 20.305 7 23.544 6.766L23.771 6.754L24 6.75ZM27.75 19.75C27.4405 19.7499 27.142 19.8645 26.9122 20.0718C26.6824 20.279 26.5377 20.5642 26.506 20.872L26.5 21V33L26.506 33.128C26.5364 33.4369 26.6805 33.7234 26.9105 33.9318C27.1404 34.1402 27.4397 34.2557 27.75 34.2557C28.0603 34.2557 28.3596 34.1402 28.5895 33.9318C28.8195 33.7234 28.9636 33.4369 28.994 33.128L29 33V21L28.994 20.872C28.9623 20.5642 28.8176 20.279 28.5878 20.0718C28.358 19.8645 28.0595 19.7499 27.75 19.75V19.75ZM20.25 19.75C19.9405 19.7499 19.642 19.8645 19.4122 20.0718C19.1824 20.279 19.0377 20.5642 19.006 20.872L19 21V33L19.006 33.128C19.0364 33.4369 19.1805 33.7234 19.4105 33.9318C19.6404 34.1402 19.9397 34.2557 20.25 34.2557C20.5603 34.2557 20.8596 34.1402 21.0895 33.9318C21.3195 33.7234 21.4636 33.4369 21.494 33.128L21.5 33V21L21.494 20.872C21.4623 20.5642 21.3176 20.279 21.0878 20.0718C20.858 19.8645 20.5595 19.7499 20.25 19.75V19.75ZM24.168 10.255L24 10.25C23.2997 10.25 22.6258 10.5171 22.1157 10.9969C21.6056 11.4767 21.2978 12.133 21.255 12.832L21.25 13.001L26.75 13C26.75 12.2997 26.4829 11.6258 26.0031 11.1157C25.5233 10.6056 24.867 10.2978 24.168 10.255V10.255Z"
                  fill="black"
                />
              </svg>
            </span> */}
            <span>
              <svg
                width="24"
                height="23"
                viewBox="0 0 24 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.89526 17.2145L15.6095 11.5002L9.89526 5.78589"
                  stroke="#1DE9B6"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </div>
        </div>
      </Link>
    </article>
  );
}

export default TournamentCard;
