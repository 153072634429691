import { createContext } from "react";

const initialState = {
  addTournamentModalState: false,
  tournaments: [
    {
      country: "",
      createdBy: "",
      device: "",
      game: "",
      imgUrl: "",
      participants: [],
      matches: [],
      playtype: "",
      status: "",
      title: "",
      ugtid: "",
      registrationDate: "",
      startDate: "",
      endDate: "",
    },
  ],
  scoreModalState: false,
  loginModalState: false,
  successModalState: false,
  termsModalState: false,
  errorModalState: false,
  errorHeroModalState: false,
  confirmationModalState: false,
  _successModalState: false,
  user: {
    email: "",
    userName: "",
    verified: false,
    migrationMessage: false,
    logged: false,
  },

  //   user: {
  //     email: "",
  //     userName: "",
  //     verified: false,
  //     migrationMessage: false,
  //   },
};

const context = createContext();

export { initialState, context };
