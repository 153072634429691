import { initialState } from "./Context";

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_ADD_TOURNAMENT_MODAL_STATE":
      return { ...state, addTournamentModalState: payload };
    case "GET_SET_SCORE_MODAL_STATE":
      return { ...state, scoreModalState: payload };
    case "GET_TOURNAMENTS":
      return { ...state, tournaments: payload };
    case "GET_MODAL_STATE":
      return { ...state, loginModalState: payload };
    case "GET_SUCCESS_MODAL_STATE":
      return { ...state, successModalState: payload };
    case "GET_TOURNAMENT_SUCCESS_MODAL_STATE":
      return { ...state, _successModalState: payload };
    case "GET_TERMS_MODAL_STATE":
      return { ...state, termsModalState: payload };
    case "GET_ERROR_MODAL_STATE":
      return { ...state, errorModalState: payload };
    case "GET_ERROR_HERO_MODAL_STATE":
      return { ...state, errorHeroModalState: payload };
    case "GET_CONFIRMATION_MODAL_STATE":
      return { ...state, confirmationModalState: payload };
    case "USER_INFO":
      return { ...state, user: payload };
  
    default:
      return state;
  }
};
