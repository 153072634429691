import styled from "styled-components";

export const DragIconWrapper = styled.div`
  display: inline-block;
  svg {
    width: 26px;
    height: 26px;
    vertical-align: middle;
    padding-right: 1rem;
    fill: #B388FF;
  }
`;
export const ListContainer = styled.div`
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  margin: 2rem auto;
  max-width: 30rem;
  padding: .5rem 2rem 2rem;
  border-radius: 0.2rem;
  box-shadow: 0.1rem 0.1rem 0.4rem #aaaaaa;
`;
export const ListItem = styled.div`
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 22px;
letter-spacing: 0.05em;
color: #B388FF;
padding: 0.8rem 1.3000000000000007rem;
border-bottom: 1px solid #424242;
display: flex;
width: 100%;
flex-flow: row;
align-items: center;
background: linear-gradient(121.38deg,#424242 0%,#212121 100%);
   &:last-child {
    border-bottom: none;
  }
  span {
    display: inline-block;
    vertical-align: middle;
  }
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.25);
`;