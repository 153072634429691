import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import FeatherIcon from 'feather-icons-react';

const options = {
    // you can also just use 'bottom center'
    position: positions.TOP_CENTER,
    timeout: 5000,
    offset: '10px',
    // you can also just use 'scale'
    transition: transitions.SCALE
  }

  const AlertTemplate = ({ style, options, message, close }) => (
    <div style={style}>
      <div className="alert-container">
        {options.type === 'info' && (
          <FeatherIcon icon="alert-circle" stroke="white"/>
        )}
        {options.type === 'success' && (
          <FeatherIcon icon="check-circle" stroke="green"/>
        )}
        {options.type === 'error' && (
          <FeatherIcon icon="x-circle" stroke="red"/>
        )}
        <p>{message}</p>
        <button onClick={close}><FeatherIcon icon="x" stroke="white"/></button>
      </div>
    </div>
  )

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <AlertProvider template={AlertTemplate} {...options}>
      <App />
    </AlertProvider>
  </Router>
);
