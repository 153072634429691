import React, { useContext, useEffect, useState } from "react";
import { context } from "../../Store/Context";
import styles from "./styles.module.scss";
import api from "../../api/tournaments";
import { getUserId, isAuthenticated } from "../Global/Auth";
import { useNavigate, Navigate, useParams } from "react-router-dom";
import { useAlert } from 'react-alert'

function Edit({tournament}) {
  const { state, dispatch } = useContext(context);
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState("");
  const [device, setDevice] = useState("");
  const [game, setGame] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [registrationDate, setRegistrationDate] = useState("");
  const [gameType, setGameType] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [country, setCountry] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  //const [tournament, setTournament] = useState("");
  const alert = useAlert();
  let navigate = useNavigate();

  // const [updatedTournaments, setUpdatedTournamaents] = useState([]);
  // useEffect(() => {
  //   console.log(state.tournaments);
  //   setUpdatedTournamaents(state.tournaments);
  //   console.log(updatedTournaments);
  // }, []);
  useEffect(() => {
    console.log(state.tournament)
    let tournaments = api.get(`/latest/tournament/new/${id}`);
   console.log(tournament);
    //setTournament(tournaments);
    isAuthenticated().then((user) => {
      setCreatedBy(user.username);
      console.log(user.username);
    });
  }
  , []);

  const handleSubmit = async (e) => {
    e.preventDefault();



    const newTournament = {
      title: title,
      status: status,
      game: game,
      startDate: startDate,
      endDate: endDate,
      registrationDate: registrationDate,
      participants: [],
      matches: [],
      imgUrl: imgUrl,
      createdBy: createdBy,
      device: device,
      country: country,
      playtype: gameType,
    };
    try {
      const response = await api.put(`/latest/tournament/${tournament.ugtid}`, newTournament)
      // let _updatedTournaments = state.tournaments;
      // _updatedTournaments.push(response.data);
      // dispatch({
      //   type: "GET_TOURNAMENTS",
      //   payload: _updatedTournaments,
      // })
      navigate(`/tournament/${response.ugtid}`);
      alert.success("Tournament edited successfully");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <section className={styles.hero}>
      <div className="container-md">
            <h1>Create Tournament</h1>
            <div className="tournament-modal-body">
              <div className="content">
                <h3>Basic Info</h3>
                <form onSubmit={handleSubmit}>
                  <input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    type="text"
                    placeholder="Tournament name"
                    required
                  />
                  <input
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    type="text"
                    placeholder="status"
                    required
                    hidden
                  />
                  <select
                    value={device}
                    name="device"
                    id="device"
                    onChange={(e) => setDevice(e.target.value)}
                  >
                    <option value="Mobile">Mobile</option>
                    <option value="PC">PC</option>
                    <option value="Console">Console</option>
                  </select>
                  <input
                    type="text"
                    value={startDate}
                    placeholder="Start date"
                    onChange={(e) => setStartDate(e.target.value)}
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                    min="2022-01-01"
                    max="2023-01-01"
                  />

                  <input
                    type="text"
                    value={endDate}
                    placeholder="End date"
                    onChange={(e) => setEndDate(e.target.value)}
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                    min="2022-01-01"
                    max="2023-01-01"
                  />
                  <input
                    type="text"
                    value={registrationDate}
                    placeholder="Registration date"
                    onChange={(e) => setRegistrationDate(e.target.value)}
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                    min="2022-01-01"
                    max="2023-01-01"
                  />
                  <input
                    type="text"
                    value={imgUrl}
                    onChange={(e) => setImgUrl(e.target.value)}
                    placeholder="img URL"
                    required
                  />

                  <input
                    value={createdBy}
                    onChange={(e) => setCreatedBy(e.target.value)}
                    type="text"
                    placeholder="createdBy"
                    required
                    hidden

                  />

                  <select
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    name="country"
                    id="country"
                  >
                    <option value="Malaysia">Malaysia</option>
                    <option value="Indonasia">Indonasia</option>
                    <option value="Japan">Japan</option>
                  </select>
                  <h3>Game Info</h3>
                  <p>Currently we only allow Single Elimination Format tournaments. <br></br>But stay tuned to see more additions to the platform!</p>
                  <select
                    value={game}
                    onChange={(e) => setGame(e.target.value)}
                    name="game"
                    id="game"
                  >
                    <option value="Dota 2">Dota 2</option>
                    <option value="League of legends">League of legends</option>
                    <option value="Call of duty">Call of duty</option>
                  </select>
                  <select
                    value={gameType}
                    onChange={(e) => setGameType(e.target.value)}
                    name="type"
                    id="type"
                  >
                    <option value="1v1">1v1</option>
                    <option value="2v2">2v2</option>
                    <option value="3v3">3v3</option>
                  </select>
                  {/* <input
            type="time"
            id="appt"
            name="appt"
            min="09:00"
            max="18:00"
            required
          /> */}
                  <button className="btn-primary">Game on!</button>
                </form>
              </div>
            </div>
      </div>
    </section>
  );
}

export default Edit;
